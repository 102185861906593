<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách nguồn đơn hàng'">
      <template v-slot:toolbar>
        <b-button
          size="sm"
          variant="primary"
          v-if="checkPermission('ORDER_SOURCE_INSERT')"
          v-bind:style="btnCreate"
          class="font-weight-bolder"
          @click="addRow"
        >
          <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
        </b-button>
      </template>
      <template v-slot:preview>
        <b-row>
          <b-col md="6" class="mb-5">
            <b-row>
              <b-col md="6">
                <b-input
                  size="sm"
                  placeholder="Tìm kiếm"
                  v-model="search"
                  v-on:keyup.enter="fetchOrderResource"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <table
              class="table table-bordered table-vertical-center table-hover"
            >
              <thead>
                <tr>
                  <th class="text-center">STT</th>
                  <th class="text-center">Tên</th>
                  <th class="text-center">Trạng thái</th>
                  <th class="text-center"></th>
                </tr>
              </thead>

              <tbody v-for="(item, index) in orderSources" :key="index">
                <OrderResourceItem
                  :orderItem="item"
                  :listFeature="listFeature"
                  @updateItem="updateOrderResource"
                  @deleteItem="deleteOrderResource"
                  @addItem="addNewOrderResource"
                  @cancelAdd="cancelAdd"
                />
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="d-flex justify-content-between">
              <p class="mt-3 text-dark p-0" style="font-weight: 500">
                Tổng số :
                {{ totalItem }}
              </p>

              <div class="d-flex justify-content-end">
                <b-col>
                  <b-pagination-nav
                    class="custom-pagination"
                    v-if="numberOfPage >= 2"
                    :link-gen="linkGen"
                    :number-of-pages="numberOfPage"
                    use-router
                    @change="fetchOrderResource"
                    align="right"
                    first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                    prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                    next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                    last-class="page-item-last btn btn-icon btn-sm my-1 "
                    page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                  >
                    <template v-slot:first-text>
                      <span>
                        <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                      </span>
                    </template>

                    <template v-slot:prev-text>
                      <i class="ki ki-bold-arrow-back icon-xs"></i>
                    </template>

                    <template v-slot:next-text>
                      <i class="ki ki-bold-arrow-next icon-xs"></i>
                    </template>

                    <template v-slot:last-text>
                      <span class="text-info">
                        <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                      </span>
                    </template>
                  </b-pagination-nav>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}
</style>

<script>
import OrderResourceItem from '../../components/OrderResourceItem.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import SavaDataUtil from './../../../utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      search: '',
      orderSources: [],
      itemChoice: null,
      listFeature: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      totalItem: 0,
    };
  },
  components: {
    KTCodePreview,
    OrderResourceItem,
  },
  created() {
    this.getListFeature();
    this.fetchOrderResource();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nguồn đơn hàng', route: '/order-source' },
      { title: 'Danh sách nguồn đơn hàng' },
    ]);
  },
  methods: {
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    addRow() {
      const data = {
        id: '',
        name: '',
        status: 1,
        createAt: '',
      };
      if (this.orderSources[0].name != '') {
        this.orderSources.unshift(data);
      }
    },
    cancelAdd() {
      this.orderSources.shift();
    },
    fetchOrderResource() {
      this.page = this.$route.query.page || 1;
      const LIMIT = 10;
      const params = {
        name: this.search,
        limit: LIMIT,
        page: this.page,
      };
      ApiService.query(`orderSources`, { params: params }).then(({ data }) => {
        this.orderSources = [];
        data.data.list_order_source.forEach((item, index) => {
          this.orderSources.push({
            count: (this.page - 1) * LIMIT + ++index,
            ...item,
          });
        });
        if (data.data.total_page == 1) {
          this.linkGen(1);
        }
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
      });
    },
    addNewOrderResource(item) {
      ApiService.post('orderSources', {
        data: { name: item.name, status: item.status },
      }).then(() => {
        this.fetchOrderResource();
      });
    },
    updateOrderResource(item) {
      ApiService.put(`orderSources?id=${item.id}`, {
        data: { id: item.id, name: item.name, status: item.status },
      }).then(() => {
        this.fetchOrderResource();
      });
    },
    deleteOrderResource(id) {
      ApiService.delete(`orderSources?id=${id}`).then(() => {
        this.fetchOrderResource();
      });
    },
  },
};
</script>
